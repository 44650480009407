//Framework imports
import React, { lazy } from "react";
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
// eslint-disable-next-line
import { CircularProgress } from "@material-ui/core";

import "./index.css"
import customTheme from "./theme";

//Application components
const Root = lazy(() => import("./pages/Root"));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
        <Router>
          <Root />
        </Router>
    </ThemeProvider>
  </React.StrictMode>
);
