import { createTheme } from "@material-ui/core/styles";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#005748",
    },

    secondary: {
      main: "#fffce1",
    },

    brandRed: {
      main: "#f9423a",
      hover: "#d93a32",
    },
  },
  typography: {
    fontFamily: ["acumin-pro", "sans-serif"],
    h1: {
      fontFamily: ["bookmania", "serif"],
    },
    h2: {
      fontFamily: ["bookmania", "serif"],
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": ["acumin-pro", "sans-serif"],
      },
    }
  },
});
export default customTheme;
